import { isValid } from '@common/utils';

export function descendingComparator(valueA: unknown, valueB: unknown) {
  if ((typeof valueA === 'string' && isValid(valueA)) || (typeof valueB === 'string' && isValid(valueB))) {
    const dateA = new Date(String(valueA)).getTime();
    const dateB = new Date(String(valueB)).getTime();

    if (dateB < dateA) {
      return -1;
    }
    if (dateB > dateA) {
      return 1;
    }

    return 0;
  }

  if (typeof valueA === 'string' && typeof valueB === 'string') {
    const stringA = valueA.toLowerCase();
    const stringB = valueB.toLowerCase();

    if (stringB < stringA) {
      return -1;
    }
    if (stringB > stringA) {
      return 1;
    }

    return 0;
  }

  if ((valueB as number) < (valueA as number)) {
    return -1;
  }
  if ((valueB as number) > (valueA as number)) {
    return 1;
  }

  return 0;
}
