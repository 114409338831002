import { createAsyncThunk } from '@reduxjs/toolkit';

import { tokenService } from '@common/TokenService';
import { userProfileService } from '@common/UserProfileService';
import { fetch } from '@common/fetch';
import { supplementalWaiverService } from '@common/servicesInstances/suplementalWeaver';
import { SamlNavigationType, UserAuthResponseType } from '@common/types';
import { getErrorMessage } from '@common/utils/getErrorMessage';
import { removeImpersonation, setImpersonation } from '@common/utils/impersonation';
import { matchPasswordErrors } from '@common/utils/matchPasswordErrors';
import { enqueueSnackbar } from '@modules/Snackbar';
import { gtmEvent, GtmEventsEnum } from '@modules/gtm';
import { validateRecaptcha } from '@modules/recaptcha';

import { locale } from '../locale';
import { ImpersonatePayload, LoginPayload, LogoutPayload } from './types';

const setAuthData = (res?: { data: UserAuthResponseType }, saveUserData = true) => {
  const wellnessId = res?.data?.user?.wellness_id;

  if (wellnessId) {
    tokenService.saveToken(res?.data?.token as string, wellnessId as string);
    if (saveUserData) {
      userProfileService.saveAuthUserData(res?.data, wellnessId as string);
    }
  }
};

export const login = createAsyncThunk(
  'Login/login',
  async ({ username, password }: LoginPayload, { rejectWithValue }) => {
    try {
      const data = fetch.post({
        url: '/Authentication/sign-in',
        skipAuth: true,
        skipAlert: true,
        data: {
          username,
          password,
        },
      });
      const res = (await data.response) as { data: UserAuthResponseType };
      tokenService.removeToken();
      userProfileService.removeUserProfile();

      setAuthData(res);
      gtmEvent(GtmEventsEnum.signIn);

      return res?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const logout = createAsyncThunk('Login/logout', async (_: LogoutPayload, { rejectWithValue }) => {
  try {
    const data = fetch.post({
      url: '/Authentication/revoke-token',
      skipAlert: true,
    });
    const res = await data.response;
    tokenService.removeToken();
    userProfileService.removeUserProfile();
    removeImpersonation();

    supplementalWaiverService.removeSign();

    return res?.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const impersonate = createAsyncThunk(
  'Login/impersonate',
  async (payload: ImpersonatePayload, { rejectWithValue }) => {
    const headers = payload.token ? { headers: { Authorization: `Bearer ${payload.token}` } } : {};

    try {
      const data = fetch.post({
        url: '/Authentication/impersonate',
        skipAlert: true,
        data: {
          user_id: payload.userId,
        },
        ...headers,
      });

      const res = (await data.response) as { data: UserAuthResponseType };
      tokenService.removeToken();
      userProfileService.removeUserProfile();

      setAuthData(res);
      setImpersonation();

      supplementalWaiverService.removeSign();
      return res?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const mobileLogin = createAsyncThunk('Login/mobileLogin', async (jumpToken: string, { rejectWithValue }) => {
  try {
    const data = fetch.post({
      url: '/Authentication/portal-jump',
      skipAuth: true,
      skipAlert: true,
      data: {
        jump_token: jumpToken,
      },
    });
    const res = (await data.response) as { data: UserAuthResponseType };

    setAuthData(res);
    gtmEvent(GtmEventsEnum.portalJump);

    return res?.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const samlLogin = createAsyncThunk('Login/samlLogin', async (token: string, { rejectWithValue }) => {
  try {
    const data = fetch.post({
      url: '/Authentication/saml-jump',
      skipAuth: true,
      skipAlert: true,
      data: {
        jump_token: token,
      },
    });
    const res = (await data.response) as { data: UserAuthResponseType };

    setAuthData(res, res?.data?.saml_navigation_type === SamlNavigationType.AutoLogin);
    gtmEvent(GtmEventsEnum.samlJump);

    return res?.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

type SignUpAndLoginParams = {
  recaptchaToken: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
};

export const signUpAndLogin = createAsyncThunk(
  'Login/signUpAndLogin',
  async ({ data, recaptchaToken }: SignUpAndLoginParams, { rejectWithValue, dispatch }) => {
    try {
      await validateRecaptcha(recaptchaToken);

      const responseData = fetch.post({
        url: '/Authentication/sign-up-complete',
        skipAuth: true,
        skipAlert: true,
        data,
      });
      const res = (await responseData.response) as { data: UserAuthResponseType };

      setAuthData(res);
      gtmEvent(GtmEventsEnum.signUpComplete);

      dispatch(
        enqueueSnackbar({
          message: locale.sign_up_complete.title,
          options: {
            variant: 'success',
            subtitle: locale.sign_up_complete.subtitle,
          },
        }),
      );

      return res?.data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      const message = matchPasswordErrors(error?.response?.data?.error);
      dispatch(
        enqueueSnackbar({
          message: message || getErrorMessage(error),
          options: {
            variant: 'error',
          },
        }),
      );
      return rejectWithValue(error);
    }
  },
);

export const logoutWithoutRevoke = createAsyncThunk('logoutWithoutRevoke', async () => {
  tokenService.removeToken();
  userProfileService.removeUserProfile();
  removeImpersonation();
});

export const reset = createAsyncThunk('reset', async () => {
  removeImpersonation();
});
