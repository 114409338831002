export const ROUTES = {
  MAIN_PAGE: '/',
  APPOINTMENTS: {
    ROOT: 'appointments',
    SCHEDULE_APPOINTMENT: 'schedule',
    COMPLETE_LIST_OF_VISIT_REASONS: 'complete_list_of_visit_reasons',
    CHOOSE_TIME: 'choose-time',
    CONFIRM: 'confirm',
    SCHEDULED: 'scheduled',
    CANCELLATION: 'cancellation',
    HEALTH_CENTER_LOCATIONS: 'health_center_locations',
    PROVIDERS: {
      ROOT: 'providers',
      BIO: ':id',
    },
    HEALTH_CENTER_SERVICES: 'health_center_services',
    HEALTH_CENTER_LOCATION_DETAILS: 'health_center_locations/:id',
    PHYSICAL_THERAPY: 'physical_therapy',
  },
  MY_HEALTH: {
    ROOT: 'my_health',
    MY_HEALTH_DASHBOARD: 'dashboard',
    TEST_RESULTS: 'test_results',
    RISK_PROFILE: 'risk_profile',
    HEALTH_SUMMARY: {
      ROOT: 'health_summary',
      MEDICATIONS: 'medications',
      ALLERGIES: 'allergies',
      IMMUNIZATIONS: 'immunizations',
      HEALTH_ISSUES: 'health_issues',
      SCREENINGS: 'screenings',
      VISIT_SUMMARIES: 'visit_summaries',
      PATIENT_EDUCATION: 'patient_education',
      DOCUMENTS: 'documents',
    },
    QUESTIONNAIRES: 'questionnaires',
    QUESTIONNAIRES_START: 'questionnaires/:id',
    GOALS: 'goals',
    CARE_GUIDELINES: {
      ROOT: 'care_guidelines',
      ITEM: ':name',
    },
    DOCUMENTS: 'documents',
  },
  WELLNESS: {
    ROOT: 'wellness',
    INCENTIVES: 'incentives',
    CHALLENGES: 'challenges',
    WELLNESS_LOGS: 'wellness_logs',
  },
  RESOURCES: {
    ROOT: 'resources',
    ALL: 'all',
    HEALTHWISE: 'healthwise',
    WELLNESS_WORKSHOPS: 'wellness_workshops',
    WELLNESS_WORKSHOPS_DETAILS: 'wellness_workshops/:id',
    WEBINARS: 'webinars',
    BLUEBOOK: 'bluebook',
    COMMUNITY: 'community',
  },
  HELP: {
    ROOT: 'help',
    FAQ: 'faq',
    FEEDBACK: 'feedback',
    SPANISH_HELP: 'spanish_language_assistance',
  },
  MESSAGES: {
    ROOT: 'messages',
    EDIT: 'edit',
  },
  PROFILE: {
    ROOT: 'profile',
  },
  MEDICATION_REFILLS: {
    ROOT: `medication_refills`,
  },
  PRESCRIPTIONS: '/prescriptions',
  ARTICLE: '/article',
  // TODO-ekvasiuk mock routes
  SCHEDULE: '/schedule',
  REFILL: '/refill',
  RECORDS: '/records',
  COVID_SCREENING: {
    ROOT: 'covid_screenings',
    RESULT: 'result',
  },
  INCENTIVE: '/incentive',
  LOGIN: '/login',
  SIGN_IN: '/sign_in',
  IMPERSONATION: '/users/:userId/impersonations',
  SAML: '/saml/consume',
  HEALTH_RISK_ASSESSMENT_CONSENT: '/health_risk_assessment_consent',
  LATEST_NEWS: '/latest_news',
  CATEGORIES: {
    ROOT: 'categories',
    CATEGORY: ':id',
  },

  FORMS: {
    ROOT: 'forms',
  },
  PASSWORD_RECOVERY: {
    ROOT: '/password-recovery',
    RESET_PASSWORD: '/reset-password-page/:token',
  },
  REGISTRATION: {
    ROOT: '/registration',
    SIGN_UP: '/sign_up',
    REGISTRATION_CAMPAIGN: '/registration/:campaign',
    SIGN_UP_CAMPAIGN: '/sign_up/:campaign',
    COMPLETE: '/user_registrations/:token',
    COMPLETE_WITHOUT_TOKEN: '/user_registrations',
    VALIDATION: '/portal-lookup'
  },
  MAINTENANCE: '/maintenance',
  SERVICE: '/500',
};

// How to use it:
// if module is outside the ROOT router we have to add '/' before route and use PATHS constant in links
// if module is inside the ROOT router we don't add '/' before route and use ROUTES constant directly
export const PATHS = {
  IMPERSONATION: `${ROUTES.IMPERSONATION}`,
  MAIN_PAGE: `/`,
  MY_HEALTH: {
    ROOT: `/${ROUTES.MY_HEALTH.ROOT}`,
    MY_HEALTH_DASHBOARD: `/${ROUTES.MY_HEALTH.ROOT}/${ROUTES.MY_HEALTH.MY_HEALTH_DASHBOARD}`,
    TEST_RESULTS: `/${ROUTES.MY_HEALTH.ROOT}/${ROUTES.MY_HEALTH.TEST_RESULTS}`,
    RISK_PROFILE: `/${ROUTES.MY_HEALTH.ROOT}/${ROUTES.MY_HEALTH.RISK_PROFILE}`,
    HEALTH_SUMMARY: {
      ROOT: `/${ROUTES.MY_HEALTH.ROOT}/${ROUTES.MY_HEALTH.HEALTH_SUMMARY.ROOT}`,
      MEDICATIONS: `/${ROUTES.MY_HEALTH.ROOT}/${ROUTES.MY_HEALTH.HEALTH_SUMMARY.ROOT}/${ROUTES.MY_HEALTH.HEALTH_SUMMARY.MEDICATIONS}`,
      ALLERGIES: `/${ROUTES.MY_HEALTH.ROOT}/${ROUTES.MY_HEALTH.HEALTH_SUMMARY.ROOT}/${ROUTES.MY_HEALTH.HEALTH_SUMMARY.ALLERGIES}`,
      HEALTH_ISSUES: `/${ROUTES.MY_HEALTH.ROOT}/${ROUTES.MY_HEALTH.HEALTH_SUMMARY.ROOT}/${ROUTES.MY_HEALTH.HEALTH_SUMMARY.HEALTH_ISSUES}`,
      IMMUNIZATIONS: `/${ROUTES.MY_HEALTH.ROOT}/${ROUTES.MY_HEALTH.HEALTH_SUMMARY.ROOT}/${ROUTES.MY_HEALTH.HEALTH_SUMMARY.IMMUNIZATIONS}`,
      SCREENINGS: `/${ROUTES.MY_HEALTH.ROOT}/${ROUTES.MY_HEALTH.HEALTH_SUMMARY.ROOT}/${ROUTES.MY_HEALTH.HEALTH_SUMMARY.SCREENINGS}`,
      VISIT_SUMMARIES: `/${ROUTES.MY_HEALTH.ROOT}/${ROUTES.MY_HEALTH.HEALTH_SUMMARY.ROOT}/${ROUTES.MY_HEALTH.HEALTH_SUMMARY.VISIT_SUMMARIES}`,
      PATIENT_EDUCATION: `/${ROUTES.MY_HEALTH.ROOT}/${ROUTES.MY_HEALTH.HEALTH_SUMMARY.ROOT}/${ROUTES.MY_HEALTH.HEALTH_SUMMARY.PATIENT_EDUCATION}`,
      DOCUMENTS: `/${ROUTES.MY_HEALTH.ROOT}/${ROUTES.MY_HEALTH.HEALTH_SUMMARY.ROOT}/${ROUTES.MY_HEALTH.HEALTH_SUMMARY.DOCUMENTS}`,
    },

    QUESTIONNAIRES: `/${ROUTES.MY_HEALTH.ROOT}/${ROUTES.MY_HEALTH.QUESTIONNAIRES}`,
    QUESTIONNAIRES_START: `/${ROUTES.MY_HEALTH.ROOT}/${ROUTES.MY_HEALTH.QUESTIONNAIRES_START}`,
    GOALS: `/${ROUTES.MY_HEALTH.ROOT}/${ROUTES.MY_HEALTH.GOALS}`,
    CARE_GUIDELINES: {
      ROOT: `/${ROUTES.MY_HEALTH.ROOT}/${ROUTES.MY_HEALTH.CARE_GUIDELINES.ROOT}`,
      ITEM: `/${ROUTES.MY_HEALTH.ROOT}/${ROUTES.MY_HEALTH.CARE_GUIDELINES.ROOT}/${ROUTES.MY_HEALTH.CARE_GUIDELINES.ITEM}`,
    },
    DOCUMENTS: `/${ROUTES.MY_HEALTH.ROOT}/${ROUTES.MY_HEALTH.DOCUMENTS}`,
  },
  WELLNESS: {
    ROOT: `/${ROUTES.WELLNESS.ROOT}`,
    INCENTIVES: `/${ROUTES.WELLNESS.ROOT}/${ROUTES.WELLNESS.INCENTIVES}`,
    WELLNESS_LOGS: `/${ROUTES.WELLNESS.ROOT}/${ROUTES.WELLNESS.WELLNESS_LOGS}`,
    CHALLENGES: `/${ROUTES.WELLNESS.ROOT}/${ROUTES.WELLNESS.CHALLENGES}`,
  },
  APPOINTMENTS: {
    ROOT: `/${ROUTES.APPOINTMENTS.ROOT}`,
    // When you use this path don't forget to check isRestricted flag from user profile
    SCHEDULE_APPOINTMENT: `/${ROUTES.APPOINTMENTS.ROOT}/${ROUTES.APPOINTMENTS.SCHEDULE_APPOINTMENT}`,
    COMPLETE_LIST_OF_VISIT_REASONS: `/${ROUTES.APPOINTMENTS.ROOT}/${ROUTES.APPOINTMENTS.COMPLETE_LIST_OF_VISIT_REASONS}`,
    CHOOSE_TIME: `/${ROUTES.APPOINTMENTS.ROOT}/${ROUTES.APPOINTMENTS.CHOOSE_TIME}`,
    CONFIRM: `/${ROUTES.APPOINTMENTS.ROOT}/${ROUTES.APPOINTMENTS.CONFIRM}`,
    SCHEDULED: `/${ROUTES.APPOINTMENTS.ROOT}/${ROUTES.APPOINTMENTS.SCHEDULED}`,
    CANCELLATION: `/${ROUTES.APPOINTMENTS.ROOT}/${ROUTES.APPOINTMENTS.CANCELLATION}`,
    HEALTH_CENTER_LOCATIONS: `/${ROUTES.APPOINTMENTS.ROOT}/${ROUTES.APPOINTMENTS.HEALTH_CENTER_LOCATIONS}`,
    PROVIDERS: {
      ROOT: `/${ROUTES.APPOINTMENTS.ROOT}/${ROUTES.APPOINTMENTS.PROVIDERS.ROOT}`,
      BIO: `/${ROUTES.APPOINTMENTS.ROOT}/${ROUTES.APPOINTMENTS.PROVIDERS.ROOT}/${ROUTES.APPOINTMENTS.PROVIDERS.BIO}`,
    },
    HEALTH_CENTER_SERVICES: `/${ROUTES.APPOINTMENTS.ROOT}/${ROUTES.APPOINTMENTS.HEALTH_CENTER_SERVICES}`,
    HEALTH_CENTER_LOCATION_DETAILS: `/${ROUTES.APPOINTMENTS.ROOT}/${ROUTES.APPOINTMENTS.HEALTH_CENTER_LOCATION_DETAILS}`,
    PHYSICAL_THERAPY: `/${ROUTES.APPOINTMENTS.ROOT}/${ROUTES.APPOINTMENTS.PHYSICAL_THERAPY}`,
  },
  MEDICATION_REFILLS: {
    ROOT: `/${ROUTES.MEDICATION_REFILLS.ROOT}`,
  },
  MESSAGES: {
    ROOT: `/${ROUTES.MESSAGES.ROOT}`,
    EDIT: `/${ROUTES.MESSAGES.ROOT}/edit`,
  },
  RESOURCES: {
    ROOT: `/${ROUTES.RESOURCES.ROOT}`,
    ALL: `/${ROUTES.RESOURCES.ROOT}/${ROUTES.RESOURCES.ALL}`,
    HEALTHWISE: `/${ROUTES.RESOURCES.ROOT}/${ROUTES.RESOURCES.HEALTHWISE}`,
    WELLNESS_WORKSHOPS: `/${ROUTES.RESOURCES.ROOT}/${ROUTES.RESOURCES.WELLNESS_WORKSHOPS}`,
    WELLNESS_WORKSHOPS_DETAILS: `/${ROUTES.RESOURCES.ROOT}/${ROUTES.RESOURCES.WELLNESS_WORKSHOPS_DETAILS}`,
    WEBINARS: `/${ROUTES.RESOURCES.ROOT}/${ROUTES.RESOURCES.WEBINARS}`,
    BLUEBOOK: `/${ROUTES.RESOURCES.ROOT}/${ROUTES.RESOURCES.BLUEBOOK}`,
    COMMUNITY: `/${ROUTES.RESOURCES.ROOT}/${ROUTES.RESOURCES.COMMUNITY}`,
  },
  HELP: {
    ROOT: `/${ROUTES.HELP.ROOT}`,
    FAQ: `/${ROUTES.HELP.ROOT}/${ROUTES.HELP.FAQ}`,
    FEEDBACK: `/${ROUTES.HELP.ROOT}/${ROUTES.HELP.FEEDBACK}`,
    SPANISH_HELP: `/${ROUTES.HELP.ROOT}/${ROUTES.HELP.SPANISH_HELP}`,
  },
  COVID_SCREENING: {
    ROOT: `/${ROUTES.COVID_SCREENING.ROOT}`,
    RESULT: `/${ROUTES.COVID_SCREENING.ROOT}/${ROUTES.COVID_SCREENING.RESULT}`,
  },
  PROFILE: {
    ROOT: `/${ROUTES.PROFILE.ROOT}`,
  },
  FORMS: {
    ROOT: `/${ROUTES.FORMS.ROOT}`,
  },
  CATEGORIES: {
    ROOT: `${ROUTES.CATEGORIES.ROOT}`,
    CATEGORY: `/${ROUTES.CATEGORIES.ROOT}/${ROUTES.CATEGORIES.CATEGORY}`,
  },
  PASSWORD_RECOVERY: {
    ROOT: `${ROUTES.PASSWORD_RECOVERY.ROOT}`,
    RESET_PASSWORD: `${ROUTES.PASSWORD_RECOVERY.RESET_PASSWORD}`,
  },
  REGISTER: {
    ROOT: `${ROUTES.REGISTRATION.ROOT}`,
    COMPLETE: `${ROUTES.REGISTRATION.COMPLETE}`,
    COMPLETE_WITHOUT_TOKEN: `${ROUTES.REGISTRATION.COMPLETE_WITHOUT_TOKEN}`,
    SIGN_UP: `${ROUTES.REGISTRATION.SIGN_UP}`,
    SIGN_UP_CAMPAIGN: `${ROUTES.REGISTRATION.SIGN_UP_CAMPAIGN}`,
    REGISTRATION_CAMPAIGN: `${ROUTES.REGISTRATION.REGISTRATION_CAMPAIGN}`,
  },
  LOGIN: ROUTES.LOGIN,
  LATEST_NEWS: ROUTES.LATEST_NEWS,
};
