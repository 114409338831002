/* eslint-disable camelcase */
import React, { FC, useCallback, useMemo } from 'react';

import classNames from 'classnames/bind';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { Control, TextFieldWithControl } from '@components/Form';
import { Typography } from '@components/Typography';
import { SignModalFormFields } from '@modules/globalWeaver/store/types';
import { useLoadUserProfile, userProfileDataSelector } from '@modules/userProfileData';

import { locale } from './locale';

import styles from './RequiredSignature.module.css';

const cn = classNames.bind(styles);

type RequiredSignatureProps = {
  control?: Control<SignModalFormFields>;
};

export const RequiredSignature: FC<RequiredSignatureProps> = ({ control }) => {
  useLoadUserProfile();
  const { first_name, last_name } = useSelector(userProfileDataSelector);
  const patientName = useMemo(() => `${first_name} ${last_name}`, [first_name, last_name]);
  const { hint, label, placeholder, validationMessage } = locale;

  /** Requires Parent component to be wrapped with FormProvider */
  const { setValue } = useFormContext<SignModalFormFields>();

  const handleBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      setValue?.('signature', e?.target?.value?.trim());
    },

    [setValue],
  );

  const validateSignature = useCallback(
    (value: string | boolean) =>
      value.toString().toLowerCase().trim() === patientName.toLowerCase().trim() ? true : validationMessage,
    [patientName],
  );

  return (
    <div className={cn('required-signature')}>
      <Typography variant="body1-bold" component="p" className={cn('hint')}>
        {hint}
        <Typography variant="body1-bold" component="span">
          {patientName}
        </Typography>
      </Typography>
      <TextFieldWithControl
        name="signature"
        className={cn('signature')}
        label={label}
        placeholder={placeholder}
        required
        validate={validateSignature}
        control={control}
        onBlur={handleBlur}
      />
    </div>
  );
};
