import { EligibilityTypesEnum, FamilyMemberRegisterStatusEnum } from '../constants';
import { FamilyMemberType } from '../types';

type SortableObject = {
  [key: string]: any;
};

export const sortByOrder = <T extends SortableObject>(objects: T[], order: string[], field: string): T[] =>
  objects.sort((a, b) => {
    const indexA = order.indexOf(a[field]);
    const indexB = order.indexOf(b[field]);

    if (indexA === -1) return 1; // a[field] is not in order array, move to the end
    if (indexB === -1) return -1; // b[field] is not in order array, move to the front

    return indexA - indexB;
  });

export function useOrderFamilyMembers(familyMembers: Array<FamilyMemberType>): Array<FamilyMemberType> {
  const orderedFamilyMembers: Array<FamilyMemberType> = [];
  const partnersEnums = [
    EligibilityTypesEnum.Spouse,
    EligibilityTypesEnum.SameSexPartner,
    EligibilityTypesEnum.DomesticPartner,
    EligibilityTypesEnum.EmployeeSpouse,
    EligibilityTypesEnum.RetireeSpouse,
    EligibilityTypesEnum.Contractor,
    EligibilityTypesEnum.Retiree,
    EligibilityTypesEnum.COBRA,
    EligibilityTypesEnum.OnLeave,
  ] as string[];

  const dependentEnums = [EligibilityTypesEnum.Child, EligibilityTypesEnum.Dependent] as string[];

  const employee = familyMembers.filter((member) => member.eligibility_type_name === EligibilityTypesEnum.Employee);
  const partners = familyMembers.filter((member) => partnersEnums.includes(member.eligibility_type_name));

  const partnersSortedByOrder = sortByOrder(partners, partnersEnums, 'eligibility_type_name');
  const eligibleDependents = familyMembers.filter(
    (member) =>
      dependentEnums.includes(member.eligibility_type_name) &&
      member.registration_status !== FamilyMemberRegisterStatusEnum.Uneligible,
  );
  const uneligibleDependents = familyMembers.filter(
    (member) =>
      dependentEnums.includes(member.eligibility_type_name) &&
      member.registration_status === FamilyMemberRegisterStatusEnum.Uneligible,
  );

  // if employee is returned, the spouse is logged in
  if (employee && employee.length > 0) {
    orderedFamilyMembers.push(employee[0]);
  }
  orderedFamilyMembers.push(...partnersSortedByOrder);
  orderedFamilyMembers.push(...eligibleDependents);
  orderedFamilyMembers.push(...uneligibleDependents);

  return orderedFamilyMembers;
}
