/* eslint-disable camelcase */
import { createSelector, Selector } from 'reselect';

import { formatDate, formatInTimeZone } from '@common/utils/dateTimeUtil';
import {
  getNetworkForAllMembersSelector,
  NetworkPatientType,
  getNetworkByUserId,
  ClinicGroupingType,
} from '@modules/Networks';
import { getNetworkProvidersSelector } from '@modules/Networks/store';
import { getUsIanaTimeZone, getTimeZoneAbbreviation } from '@modules/StaticList';
import {
  getScheduledFamilyMembersForSelectSelector,
  isOneScheduledFamilyMemberSelector,
} from '@modules/userProfileData/store';

import {
  getFirstVisitReasonSelector,
  getUserIdSelector as getScheduleUserIdSelector,
  getVisitStateSelector,
} from '../components/ScheduleAppointment/store';
import {
  AppointmentTypeEnum,
  FilterNames,
  ANYWHERE_NETWORK,
  BIOMETRIC_WELLNESS_SCREEN_CATEGORY_ID,
  BIOMETRIC_WELLNESS_SCREEN_REASON_ID,
  LAB_WORK_VISIT_REASON_ID,
  LAB_WORK_VISIT_REASON_CATEGORY_ID,
} from '../constants';
import {
  ClinicGroupingForSelectType,
  ClinicType,
  OpenAppointmentsType,
  SchedulableStateForSelectType,
  SchedulableStateType,
} from '../types';
import {
  isVisitTypeInPerson,
  soonestAvailabilityFilter,
  filterNetworkByVisitType,
  getNetworkClinicGroupings,
  isVisitTypeVirtual,
} from '../utils';
import { ClinicWithProviderType, ProviderWithAppointmentsType, AppointmentsState, FilterType } from './types';
import { checkProviderId } from './utils';

export const getLogoutStartedSelector = (state: RootState) => state.login.logoutStarted;
export const getImpersonationSelector = (state: RootState) => state.login.impersonation;
export const getPastAppointmentsSelector = (state: RootState) =>
  state.appointments.appointments[AppointmentTypeEnum.Past];
export const getPastAppointmentsStatusSelector = (state: RootState) =>
  state.appointments.appointmentsStatus[AppointmentTypeEnum.Past];

export const getUpcomingAppointmentsSelector = (state: RootState) =>
  state.appointments.appointments[AppointmentTypeEnum.Upcoming];
export const getUpcomingAppointmentsStatusSelector = (state: RootState) =>
  state.appointments.appointmentsStatus[AppointmentTypeEnum.Upcoming];

export const userProfileDataSelector = (state: RootState) => state.userProfile.userProfileData;
export const getUserProfileStatusSelector = (state: RootState) => state.userProfile.userProfileStatus;
export const getFamilyMembersProfilesSelector = (state: RootState) => state.userProfile.familyMembersProfiles;
export const getUserIdSelector = (state: RootState) => state.userProfile.userProfileData.id;
export const getIanaTimeZoneSelector = (state: RootState) => state.userProfile.ianaTimeZone;

export const getCancelAppointmentStatusSelector = (state: RootState) => state.appointments.cancelAppointmentStatus;
export const getVisitReasonCategoriesSelector = (state: RootState) => state.appointments.visitReasonCategories;
export const getVisitReasonCategoriesStatusSelector = (state: RootState) =>
  state.appointments.visitReasonCategoriesStatus;

export const getCustomTileSelector = (state: RootState) => state.appointments.customTile;

export const getSchedulableStatesSelector = (state: RootState) => state.appointments.schedulableStates;
export const getSchedulableStatesStatusSelector = (state: RootState) => state.appointments.schedulableStatesStatus;

export const getStateIdSelector = (_: unknown, { stateId }: { stateId: Nullable<string> }) => stateId;
export const getSelectedUserIdSelector = (state: RootState) =>
  state.scheduleNewAppointment.scheduleAppointmentData?.userId;
export const getOpenAppointmentsLoadedDatesSelector = (state: RootState) =>
  state.appointments.openAppointmentsLoadedDates;
export const getOpenAppointmentsSelector = (state: RootState) => state.appointments.openAppointments;
export const getOpenAppointmentsClinicsSelector = (state: RootState) =>
  state.appointments.openAppointments.clinics ?? [];

export const getOpenAppointmentsProvidersSelector = createSelector(
  getOpenAppointmentsSelector,
  (appointments) => appointments.providers ?? [],
);

export const getBhBelowAgeLimitSelector = (state: RootState) =>
  state.appointments.openAppointments.bh_below_age_limit ?? false;
export const getBelowSchedulingAgeLimitSelector = (state: RootState) =>
  state.appointments.openAppointments.below_scheduling_age_limit ?? false;
export const getBhLimitSelector = (state: RootState) => state.appointments.openAppointments?.bh_limit ?? false;
export const getBhDeniedSelector = (state: RootState) => state.appointments.openAppointments?.bh_denied ?? false;
export const getFilterSelector = (state: RootState) => state.appointments.filter;
export const getOpenAppointmentsStatusSelector = (state: RootState) => state.appointments.openAppointmentsStatus;
export const getDuplicateAppointmentsSelector = (state: RootState) => state.appointments.duplicateAppointments;
export const getDuplicateAppointmentsStatusSelector = (state: RootState) =>
  state.appointments.duplicateAppointmentsStatus;
export const getAddressSelector = (state: RootState) => state.appointments.address;
export const getProviderSelector = (state: RootState) => state.appointments.provider;
export const getSidebarMapOpenSelector = (state: RootState) => state.appointments.sidebarMapOpen;
export const getSidebarMapFocusReturnedSelector = (state: RootState) => state.appointments.sidebarMapFocusReturned;
const timezonesSelector = (state: RootState) => state.staticList.timezones;
export const getCurrentDateSelector = (state: RootState) => state.appointments.filter.currentDate;
export const getVisitTypeSelector = (state: RootState) => state.appointments.filter.visitType;
export const getAnywhereNumberSelector = (state: RootState) => state.appointments.anywhereNumber;
export const getConfirmationDataSelector = (state: RootState) => state.appointments.confirmationData;
export const getRescheduleAppointmentSelector = (state: RootState) => state.appointments.appointmentForReschedule;
export const getAbandonmentModalOpenSelector = (state: RootState) => state.appointments.abandonmentModalOpen;
export const getBhReasonModalOpenSelector = (state: RootState) => state.appointments.bhReasonModalOpen;
export const getTimezonModalOpenSelector = (state: RootState) => state.appointments.timezoneModalOpen;
export const getAbandonAppointmentStatusSelector = (state: RootState) => state.appointments.abandonAppointmentStatus;
export const getCalendarStartDateSelector = (state: RootState) => state.appointments.calendarStartDate;
export const getScheduleErrorSelector = (state: RootState) => state.appointments.scheduleError;
export const getConfirmAndScheduleAppointmentStatusSelector = (state: RootState) =>
  state.appointments.confirmAndScheduleAppointmentStatus;
export const getRescheduleAppointmentStatusSelector = (state: RootState) =>
  state.appointments.rescheduleAppointmentStatus;
export const getIsVirtualProvidersAvailableSelector = (state: RootState) =>
  state.appointments.isVirtualProvidersAvailable;
export const getIsVirtualProvidersAvailableStatusSelector = (state: RootState) =>
  state.appointments.isVirtualProvidersAvailableStatus;
export const getIsVirtualProvidersAvailableParamsSelector = (state: RootState) =>
  state.appointments.isVirtualProvidersAvailableParams;
export const getSearchCountSelector = (state: RootState) => state.appointments.searchCount;

export const getFilterAppointmentsByUserIdSelector = (state: RootState) =>
  state.appointments.filterAppointmentsByUserId;

export const getProvidersByHealthCenterFilterSelector = createSelector(
  getOpenAppointmentsSelector,
  getFilterSelector,
  (openAppointment: OpenAppointmentsType, filter: FilterType) =>
    filter?.healthCenter && filter.healthCenter.length > 0
      ? openAppointment.providers?.filter((provider) =>
          provider.departments_mapping.some((clinicId) => filter.healthCenter.some((clinic) => clinic.id === clinicId)),
        ) ?? []
      : openAppointment.providers ?? [],
);

export const isAnywhereSelector = createSelector(getFilterSelector, (filter) =>
  filter.network.includes(ANYWHERE_NETWORK),
);

export const getBiometricWellnessScreenReasonAndCategorySelector = createSelector(
  getVisitReasonCategoriesSelector,
  (visitReasonCategories) => {
    const visitReasonCategory = visitReasonCategories?.find?.(
      (visitReasonCategory) => visitReasonCategory.id === BIOMETRIC_WELLNESS_SCREEN_CATEGORY_ID,
    );

    const visitReason = visitReasonCategory?.visit_reasons?.find?.(
      (visitReason) => visitReason.id === BIOMETRIC_WELLNESS_SCREEN_REASON_ID,
    );

    return { visitReasonCategory, visitReason };
  },
);

export const getLabVisitReasonAndCategorySelector = createSelector(
  getVisitReasonCategoriesSelector,
  (visitReasonCategories) => {
    const visitReasonCategory = visitReasonCategories?.find?.(
      (visitReasonCategory) => visitReasonCategory.id === LAB_WORK_VISIT_REASON_CATEGORY_ID,
    );

    const visitReason = visitReasonCategory?.visit_reasons?.find?.(
      (visitReason) => visitReason.id === LAB_WORK_VISIT_REASON_ID,
    );

    return { visitReasonCategory, visitReason };
  },
);

export const getUserTimeZone = createSelector(getIanaTimeZoneSelector, (ianaTimeZone) => {
  const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  return ianaTimeZone || browserTimeZone;
});

export const getTimeZoneFromFilter = createSelector(
  getFilterSelector,
  getUserTimeZone,
  (filter: AppointmentsState['filter'], userTimeZone) => filter?.[FilterNames.TimeZone]?.iana_time_zone ?? userTimeZone,
);

export const getSchedulableStatesForSelectSelector = createSelector(
  getSchedulableStatesSelector,
  (items): SchedulableStateForSelectType[] =>
    items.map((item) => ({
      id: item.code,
      name: item.name,
    })),
);

export const getStateByStateId = createSelector(
  getSchedulableStatesSelector,
  getStateIdSelector,
  (states: SchedulableStateType[], stateId: Nullable<string>) => states.find((state) => state.code === stateId),
);

export const getClinicsWithAppointmentsSelector = createSelector(
  getOpenAppointmentsSelector,
  (openAppointments: OpenAppointmentsType): ClinicWithProviderType[] =>
    openAppointments.clinics?.map?.((clinic) => {
      const appointmentsByClinic = openAppointments.appointments?.find?.(
        (appointment) => appointment.clinic_id === clinic.id,
      );
      const clinicProviders =
        openAppointments.providers?.filter?.((provider) => provider.departments_mapping.includes(clinic.id)) || [];

      const providers: ProviderWithAppointmentsType[] =
        clinicProviders.map((provider) => ({
          ...provider,
          appointments: appointmentsByClinic?.appointments || [],
        })) || ([] as ProviderWithAppointmentsType[]);

      return {
        ...clinic,
        providers,
      };
    }) || ([] as ClinicWithProviderType[]),
);

export const getFilteredClinicsWithProvidersSelector = createSelector(
  getClinicsWithAppointmentsSelector,
  getFilterSelector,
  (clinics: ClinicWithProviderType[], filter: AppointmentsState['filter']): ClinicWithProviderType[] => {
    const { healthCenter } = filter;
    let filteredClinics = clinics.filter((clinic) => !!clinic.providers?.length);

    if (healthCenter?.length) {
      filteredClinics = filteredClinics.filter((clinic) => healthCenter.some((item) => item.id === clinic.id));
    }

    return filteredClinics;
  },
);

export const getClinicsWithFilteredAppointmentsSelector: Selector<
  RootState,
  Array<ClinicWithProviderType>
> = createSelector(getFilteredClinicsWithProvidersSelector, getFilterSelector, (clinics, filter) => {
  const { timeZone, soonestAvailability, provider: providerFilter } = filter;

  return clinics.map((clinic) => {
    let filteredProviders = clinic.providers;
    const forcedTimeZone = clinic.force_sched_timezone ? clinic.timezonename : null;
    const usualTimeZone = timeZone?.id ? timeZone.iana_time_zone : null;
    const resultTimeZone = forcedTimeZone ?? usualTimeZone ?? null;

    if (providerFilter?.length) {
      filteredProviders = filteredProviders.filter((item) => providerFilter.includes(item.display_name));
    }

    filteredProviders = filteredProviders.map((provider) => {
      let { appointments } = provider;

      if (resultTimeZone) {
        appointments = appointments.map((appointment) => ({
          ...appointment,
          start_time_in_time_zone: formatInTimeZone(appointment.start_time, resultTimeZone, 'yyyy/MM/dd HH:mm:ss'),
        }));
      }

      if (soonestAvailability?.length && appointments.length) {
        appointments = soonestAvailabilityFilter(appointments, soonestAvailability);
      }

      return {
        ...provider,
        appointments,
      };
    });

    return {
      ...clinic,
      providers: filteredProviders,
    };
  });
});

export const getAvailableDaysSelector = createSelector(getClinicsWithFilteredAppointmentsSelector, (clinics) => {
  const allAppointments = clinics
    .map((clinic) => clinic.providers)
    .reduce((acc, provider) => [...acc, ...provider], [])
    .map((provider) => provider.appointments)
    .reduce((acc, appointments) => [...acc, ...appointments], []);

  const availableDates = allAppointments.reduce<Record<string, string>>((acc, item) => {
    const formattedDate = formatDate(new Date(item.start_time_in_time_zone || item.start_time), 'yyyy-MM-dd');
    const date = `${formattedDate}T00:00:00`;
    acc[date] = date;

    return acc;
  }, {});

  return Object.keys(availableDates).sort((a, b) => Date.parse(a) - Date.parse(b));
});

export const getTimezonesSelector = createSelector(timezonesSelector, (timezones) =>
  timezones.map((timezone) => {
    const usIana = getUsIanaTimeZone(timezone.iana_time_zone);
    const abbreviation = getTimeZoneAbbreviation(timezone.iana_time_zone);
    const abbreviationText = abbreviation ? ` (${abbreviation})` : '';

    const name = `${usIana ?? timezone.iana_time_zone}${abbreviationText}`;

    return {
      ...timezone,
      id: timezone.rails_time_zone,
      name,
      abbreviation,
    };
  }),
);

export const getTimezoneByUserTimeZoneSelector = createSelector(
  getTimezonesSelector,
  getUserTimeZone,
  (timeZones, userTimeZones) => timeZones.find(({ iana_time_zone }) => iana_time_zone === userTimeZones),
);

export const isVisitTypeInPersonSelector = createSelector(getVisitTypeSelector, isVisitTypeInPerson);
export const isVisitTypeVirtualSelector = createSelector(getVisitTypeSelector, isVisitTypeVirtual);

export const getSelectedUserNetworkSelector: Selector<RootState, NetworkPatientType | undefined> = createSelector(
  getNetworkForAllMembersSelector,
  getSelectedUserIdSelector,
  (networkForAllMembers, filterUserId): NetworkPatientType | undefined =>
    getNetworkByUserId(networkForAllMembers, filterUserId),
);

export const getProvidersLicensedToStateSelector = createSelector(
  getNetworkProvidersSelector,
  getVisitStateSelector,
  (providers, visitState) =>
    providers?.filter((provider) => visitState && provider.states_licensed_in?.includes(visitState)),
);

export const getProvidersLicensedToStateRescheduleSelector = createSelector(
  getNetworkProvidersSelector,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (_: any, virtualState: Nullable<string>) => virtualState,
  (providers, virtualState) =>
    providers?.filter((provider) => virtualState && provider.states_licensed_in?.includes(virtualState)),
);

export const getSelectedUserClinicGroupingsSelector: Selector<RootState, ClinicGroupingForSelectType[]> =
  createSelector(getSelectedUserNetworkSelector, getNetworkClinicGroupings);

export const getSelectedUserAndVisitTypeClinicGroupingsSelector: Selector<RootState, ClinicGroupingForSelectType[]> =
  createSelector(
    getSelectedUserClinicGroupingsSelector,
    isVisitTypeInPersonSelector,
    (clinicGroupings, isVisitTypeInPerson) => filterNetworkByVisitType(clinicGroupings, isVisitTypeInPerson),
  );

export const getClinicForConfirmationSelector = createSelector(
  getOpenAppointmentsClinicsSelector,
  getConfirmationDataSelector,
  (clinics, confirmationData) =>
    clinics.find((clinic) => clinic.id === confirmationData?.clinicId) || ({} as ClinicType),
);

export const getProviderForConfirmationSelector = createSelector(
  getOpenAppointmentsProvidersSelector,
  getConfirmationDataSelector,
  (providers, confirmationData) => providers.find((provider) => provider.id === confirmationData?.providerId),
);

export const getPatientSelector = createSelector(
  getScheduledFamilyMembersForSelectSelector,
  getConfirmationDataSelector,
  (familyMembers, confirmationData) => familyMembers.find((provider) => provider.id === confirmationData?.userId),
);

export const getVisitorUserIdSelector = createSelector(
  getConfirmationDataSelector,
  (confirmationData) => confirmationData?.userId,
);

export const isVisitorCurrentUserSelector = createSelector(
  getVisitorUserIdSelector,
  userProfileDataSelector,
  (visitorUserId, userProfile) => visitorUserId === userProfile.id,
);

export const getClinicGrouping: Selector<RootState, ClinicGroupingType | undefined> = createSelector(
  getSelectedUserAndVisitTypeClinicGroupingsSelector,
  (networks): ClinicGroupingType | undefined => networks?.[0],
);

export const shouldShowAbandonSelector = createSelector(
  isOneScheduledFamilyMemberSelector,
  getFirstVisitReasonSelector,
  getScheduleUserIdSelector,
  (isOneScheduledFamilyMember, visitReason, userId): boolean => (isOneScheduledFamilyMember ? !!visitReason : !!userId),
);

export const getDefaultProviderSelector = createSelector(
  getOpenAppointmentsProvidersSelector,
  getFilterSelector,
  (providers, filter) => providers?.find((provider) => checkProviderId(provider, filter.defaultProviderId)),
);

export const isDefaultProviderSelectedSelector = createSelector(
  getDefaultProviderSelector,
  getFilterSelector,
  (defaultProvider, { provider: filterProvider }) =>
    filterProvider?.length === 1 && defaultProvider?.display_name === filterProvider[0],
);

export const getFilteredUpcomingAppointmentsSelector = createSelector(
  getUpcomingAppointmentsSelector,
  getFilterAppointmentsByUserIdSelector,
  (appointments, userId) => {
    if (!userId) return appointments;

    return appointments.filter((appointment) => appointment.user.id === userId);
  },
);

export const getFilteredPastAppointmentsSelector = createSelector(
  getPastAppointmentsSelector,
  getFilterAppointmentsByUserIdSelector,
  (appointments, userId) => {
    if (!userId) return appointments;

    return appointments.filter((appointment) => appointment.user.id === userId);
  },
);
