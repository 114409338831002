import { extendConfigByApiUrl, extendConfigByProxyHeader } from '@common/fetch/utils';

export const METHODS = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  OPTIONS: 'OPTIONS',
  DELETE: 'DELETE',
  PATCH: 'PATCH',
} as const;

export const REFRESH_TOKEN_URL = '/Authentication/refresh-token';

const BASE_CONFIG = {
  withCredentials: true,
};

export const SKIP_ALERT_STATUSES = [404];

export const CONFIG = extendConfigByProxyHeader(extendConfigByApiUrl(BASE_CONFIG));

export const ERROR_MESSAGES = {
  CANCELED: 'canceled',
};
