import { formatDate } from '@common/utils';

import { FIELD_NAMES, ContactMethodsEnum } from '../constants';
import { FormValuesTypes } from '../types';
import { BasicInfo, ValidateUserPreregistrationUserPayload } from './types';

const DEFAULT_CONTACT_METHODS = `${ContactMethodsEnum.Email}, ${ContactMethodsEnum.Home}, ${ContactMethodsEnum.Cell}`;

export const convertFormToPotentialSignUpData = (data: FormValuesTypes, campaign?: Nullable<string>) => ({
  potential: {
    id: 0,
    email: data[FIELD_NAMES.EMAIL] || null,
    first_name: data[FIELD_NAMES.FIRST_NAME] || null,
    last_name: data[FIELD_NAMES.LAST_NAME] || null,
    born_on: data[FIELD_NAMES.BIRTH_DATE]
      ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore nullable value checked
        formatDate(data[FIELD_NAMES.BIRTH_DATE], 'yyyy-MM-dd')
      : null,
    home_phone_number: data[FIELD_NAMES.HOME_PHONE_NUMBER] || null,
    cell_phone_number: data[FIELD_NAMES.CELL_PHONE_NUMBER] || null,
    proposed_employer: data[FIELD_NAMES.PROPOSED_EMPLOYER] || null,
    proposed_employee_first_name: data[FIELD_NAMES.PROPOSED_EMPLOYEE_FIRST_NAME] || null,
    proposed_employee_ssn: data[FIELD_NAMES.PROPOSED_EMPLOYEE_SOCIAL_SECURITY_NUMBER] || null,
    social_security_number: data[FIELD_NAMES.SOCIAL_SECURITY_NUMBER] || null,
    proposed_employee_last_name: data[FIELD_NAMES.PROPOSED_EMPLOYEE_LAST_NAME] || null,
    proposed_employee_born_on: data?.[FIELD_NAMES.PROPOSED_EMPLOYEE_BIRTH_DATE]
      ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore nullable value checked
        formatDate(data[FIELD_NAMES.PROPOSED_EMPLOYEE_BIRTH_DATE], 'yyyy-MM-dd')
      : null,
    proposed_eligibility_type_id: Number(data[FIELD_NAMES.PROPOSED_ELIGIBILITY]) || null,
    campaign_slug: campaign ?? null,
  },
  home_address: {
    id: 0,
    street_address1: data[FIELD_NAMES.STREET_ADDRESS1] || null,
    street_address2: data[FIELD_NAMES.STREET_ADDRESS2] || null,
    city: data[FIELD_NAMES.CITY] || null,
    state_abbr: data[FIELD_NAMES.STATE_ABBR] || null,
    zip_code: data[FIELD_NAMES.ZIP] || null,
  },
});

const trasnformToPreferredOrderOfContact = (data: FormValuesTypes) => {
  const contactMethods = [
    data[FIELD_NAMES.PREFERRED_CONTACT_METHOD_1],
    data[FIELD_NAMES.PREFERRED_CONTACT_METHOD_2],
    data[FIELD_NAMES.PREFERRED_CONTACT_METHOD_3],
  ].filter(Boolean);

  return contactMethods.length === 0 ? DEFAULT_CONTACT_METHODS : contactMethods.join(',');
};

export const convertFormToSignUpData = (data: FormValuesTypes, basicInfo: Nullable<BasicInfo>) => {
  const bornOn = data[FIELD_NAMES.BIRTH_DATE] || '';

  return {
    id: basicInfo?.id ?? null,
    username: data[FIELD_NAMES.USERNAME] || basicInfo?.username || null,
    first_name: data[FIELD_NAMES.FIRST_NAME] || null,
    last_name: data[FIELD_NAMES.LAST_NAME] || null,
    born_on: typeof bornOn === 'string' ? bornOn : formatDate(bornOn, 'yyyy-MM-dd'),
    gender: data[FIELD_NAMES.GENDER] || null,
    social_security_number: data[FIELD_NAMES.SOCIAL_SECURITY_NUMBER] || null,
    home_phone_number: data[FIELD_NAMES.HOME_PHONE_NUMBER] || null,
    cell_phone_number: data[FIELD_NAMES.CELL_PHONE_NUMBER] || null,
    email: data[FIELD_NAMES.EMAIL] || null,
    home_address: {
      id: basicInfo?.home_address.id || null,
      street_address1: data[FIELD_NAMES.STREET_ADDRESS1] || null,
      street_address2: data[FIELD_NAMES.STREET_ADDRESS2] || null,
      city: data[FIELD_NAMES.CITY] || null,
      state_abbr: data[FIELD_NAMES.STATE_ABBR] || null,
      zip_code: data[FIELD_NAMES.ZIP] || null,
    },
    password: data[FIELD_NAMES.PASSWORD] || null,
    password_confirm: data[FIELD_NAMES.PASSWORD_CONFIRM] || null,
    primary_language: data[FIELD_NAMES.PRIMARY_LANGUAGE] || null,
    available_user_names: basicInfo?.available_user_names || null,
    preferred_order_of_contact: trasnformToPreferredOrderOfContact(data),
    registration_notice_token: basicInfo?.registration_notice_token || null,
  };
};

export const convertFormToValidateUserPreregistration = (data: ValidateUserPreregistrationUserPayload) => ({
  registration_notice_token: data.registration_notice_token,
  zip_code: data[FIELD_NAMES.ZIP],
  social_security_number: data[FIELD_NAMES.SOCIAL_SECURITY_NUMBER],
  born_on: formatDate(data[FIELD_NAMES.BIRTH_DATE], 'yyyy-MM-dd'),
});
