import React from 'react';

import { library } from '@fortawesome/fontawesome-svg-core';
import loadable from '@loadable/component';
import { useSelector } from 'react-redux';
import { Route, Routes, Navigate } from 'react-router-dom';

import { alertInterceptor, fetch, refreshInProcessInterceptor, refreshInterceptor } from '@common/fetch';
import { trackPageRequestsInterceptor } from '@common/interceptors/trackPageRequestsInterceptor';
import { ParametricNavigate, PrivateRoute, useReload } from '@common/router';
import { ROUTES } from '@common/routes';
import { ICONS } from '@components/FaIcon/icons';
import { BaseLayout } from '@modules/BaseLayout';
import { useMaintenance } from '@modules/InternalError';
import { LoadingLocker } from '@modules/LoadingLocker';
import { logoutWithoutRevoke, useLogout, useSalesforceImpersonationRedirect } from '@modules/Login';
import { enqueueSnackbar, useNotifier } from '@modules/Snackbar';
import { UserActivityModal } from '@modules/UserActivityModal';
import { useGtmUserProfileParams } from '@modules/gtm/hooks/useGtmUserProfileParams';
import { useRouteDetails } from '@modules/route';
import { getIsRestrictedSelector } from '@modules/userProfileData';

import { store } from './store';

library.add(ICONS);

fetch.registerResponseInterceptor({
  onError: refreshInterceptor(() => store.dispatch(logoutWithoutRevoke()), fetch),
});

fetch.registerResponseInterceptor({
  onError: alertInterceptor((message: string) =>
    store.dispatch(
      enqueueSnackbar({
        message,
        options: {
          variant: 'error',
        },
      }),
    ),
  ),
});

fetch.registerRequestInterceptor({
  onSuccess: refreshInProcessInterceptor(fetch),
});

fetch.registerRequestInterceptor({
  onSuccess: trackPageRequestsInterceptor,
});

const loadableWithFallback = (importFunction: unknown) =>
  loadable(importFunction as Parameters<typeof loadable>[0], { fallback: <LoadingLocker /> });

const AppointmentPageContainer = loadableWithFallback(
  () => import('./modules/Appointments/components/AppointmentPageContainer'),
);
const AppointmentsChoseTimePage = loadableWithFallback(
  () => import('./pages/Appointments/pages/AppointmentsChoseTimePage'),
);
const AppointmentsScheduledPage = loadableWithFallback(
  () => import('./pages/Appointments/pages/AppointmentsScheduledPage'),
);
const ScheduleAppointmentsPage = loadableWithFallback(
  () => import('./pages/Appointments/pages/ScheduleAppointmentsPage'),
);
const ScheduleConfirmationPage = loadableWithFallback(
  () => import('./pages/Appointments/pages/ScheduleConfirmationPage'),
);
const CompleteListOfVisitReasonsPage = loadableWithFallback(
  () => import('./pages/Appointments/pages/CompleteListOfVisitReasonsPage'),
);
const AppointmentCancellationConfirmPage = loadableWithFallback(
  () => import('./pages/Appointments/pages/AppointmentCancellationConfirmPage'),
);

const MainPage = loadableWithFallback(() => import('./pages/MainPage'));
const MedicationRefillsPage = loadableWithFallback(() => import('./pages/MedicationRefillsPage'));
const UserProfilePage = loadableWithFallback(() => import('./pages/UserProfilePage'));
const MyHealthPage = loadableWithFallback(() => import('./pages/MyHealthPage'));

const DashboardPage = loadableWithFallback(() => import('./pages/MyHealthPage/components/DashboardPage'));
const RiskProfilePage = loadableWithFallback(() => import('./pages/MyHealthPage/components/RiskProfilePage'));
const MyGoalsPage = loadableWithFallback(() => import('./pages/MyHealthPage/components/MyGoalsPage'));
const TestResultsPage = loadableWithFallback(() => import('./pages/MyHealthPage/components/TestResultsPage'));
const HealthSummaryPage = loadableWithFallback(() => import('./pages/MyHealthPage/components/HealthSummaryPage'));
const MedicationsPage = loadableWithFallback(() => import('./pages/MyHealthPage/components/MedicationsPage'));
const AllergiesPage = loadableWithFallback(() => import('./pages/MyHealthPage/components/AllergiesPage'));
const PatientEducationPage = loadableWithFallback(() => import('./pages/MyHealthPage/components/PatientEducationPage'));
const ImmunizationsPage = loadableWithFallback(() => import('./pages/MyHealthPage/components/ImmunizationsPage'));
const HealthIssuesPage = loadableWithFallback(() => import('./pages/MyHealthPage/components/HealthIssuesPage'));
const ScreeningsPage = loadableWithFallback(() => import('./pages/MyHealthPage/components/ScreeningsPage'));
const VisitSummariesPage = loadableWithFallback(() => import('./pages/MyHealthPage/components/VisitSummariesPage'));
const DocumentsPage = loadableWithFallback(() => import('./pages/MyHealthPage/components/DocumentsPage'));
const CareGuidelinesPage = loadableWithFallback(() => import('./pages/MyHealthPage/components/CareGuidelinesPage'));
const CareGuidelinesListPage = loadableWithFallback(
  () => import('./pages/MyHealthPage/components/CareGuidelinesListPage'),
);
const CareGuidelinesItemPage = loadableWithFallback(
  () => import('./pages/MyHealthPage/components/CareGuidelinesItemPage'),
);
const QuestionnairesPage = loadableWithFallback(() => import('./pages/MyHealthPage/components/QuestionnairesPage'));
const QuestionnairesListPage = loadableWithFallback(
  () => import('./pages/MyHealthPage/components/QuestionnairesListPage'),
);

const MessagesPage = loadableWithFallback(() => import('./pages/MessagesPage'));
const EditMessagePage = loadableWithFallback(() => import('./pages/EditMessagePage'));
const ProvidersPage = loadableWithFallback(() => import('./pages/ProvidersPage'));
const HealthCenterServicesPage = loadableWithFallback(() => import('./pages/HealthCenterServicesPage'));
const HealthCenterLocationsPage = loadableWithFallback(() => import('./pages/HealthCenterLocationsPage'));
const HealthCenterDetailsPage = loadableWithFallback(() => import('./pages/HealthCenterDetailsPage'));
const LatestNewsPage = loadableWithFallback(() => import('./pages/LatestNewsPage'));
const FormsPage = loadableWithFallback(() => import('./pages/FormsPage'));
const ResourcesPage = loadableWithFallback(() => import('./pages/ResourcesPage'));
const WebinarLibraryPage = loadableWithFallback(() => import('./pages/WebinarLibraryPage'));
const WellnessWorkShopsPage = loadableWithFallback(() => import('./pages/WellnessWorkShopsPage'));
const HealthwisePage = loadableWithFallback(() => import('./pages/HealthwisePage'));
const HealthcareBluebookPage = loadableWithFallback(() => import('./pages/HealthcareBluebookPage'));
const CommunityPage = loadableWithFallback(() => import('./pages/CommunityPage'));
const FAQSPage = loadableWithFallback(() => import('./pages/FAQSPage'));
const WellnessLogPage = loadableWithFallback(() => import('./pages/WellnessLogPage'));
const ChallengesPage = loadableWithFallback(() => import('./pages/ChallengesPage'));
const IncentivesPage = loadableWithFallback(() => import('./pages/IncentivesPage'));
const CustomCategoryPage = loadableWithFallback(() => import('./pages/CustomCategoryPage'));
const Covid19ScreeningPage = loadableWithFallback(() => import('./pages/Covid19ScreeningPage'));
const Covid19QuestionnairePage = loadableWithFallback(
  () => import('./pages/Covid19ScreeningPage/components/Covid19ScreeningQuestionnairePage'),
);
const Covid19ResultPage = loadableWithFallback(
  () => import('./pages/Covid19ScreeningPage/components/Covid19ScreeningResultPage'),
);
const LoginPage = loadableWithFallback(() => import('./pages/LoginPage'));
const PasswordRecoveryPage = loadableWithFallback(() => import('./pages/PasswordRecoveryPage'));
const ResetPasswordPage = loadableWithFallback(() => import('./pages/ResetPasswordPage'));
const RegistrationPage = loadableWithFallback(() => import('./pages/RegistrationPage'));
const MaintenancePage = loadableWithFallback(() => import('./pages/MaintenancePage'));
const NotFoundPage = loadableWithFallback(() => import('./pages/NotFoundPage'));
const ImpersonationPage = loadableWithFallback(() => import('./pages/ImpersonationPage'));
const SamlPage = loadableWithFallback(() => import('./pages/SamlPage'));

const MessagesContainer = loadableWithFallback(() => import('./modules/Messages/MessagesContainer'));
const ProvidersContainer = loadableWithFallback(() => import('./modules/Providers/ProvidersContainer'));

const SpanishHelpPage = loadableWithFallback(() => import('./pages/SpanishHelpPage'));
const InternalServerErrorPage = loadableWithFallback(() => import('./pages/InternalServerErrorPage'));
const PhysicalTherapyPage = loadableWithFallback(() => import('./pages/PhysicalTherapyPage'));
const ProviderBioPage = loadableWithFallback(() => import('./pages/ProviderBioPage'));

const ValidationPage = loadableWithFallback(() => import('./pages/ValidationPage'));

function App() {
  const isRestricted = useSelector(getIsRestrictedSelector);
  useSalesforceImpersonationRedirect();
  useRouteDetails();
  useLogout();

  useReload();
  useNotifier();
  useMaintenance();

  useGtmUserProfileParams();

  return (
    <Routes>
      <Route path={ROUTES.IMPERSONATION} element={<ImpersonationPage />} />
      <Route path={ROUTES.SAML} element={<SamlPage />} />
      <Route
        element={
          <PrivateRoute>
            <>
              <BaseLayout />
              <UserActivityModal />
            </>
          </PrivateRoute>
        }
      >
        <Route path={ROUTES.MAIN_PAGE} element={<MainPage />} />
        <Route path={ROUTES.MEDICATION_REFILLS.ROOT} element={<MedicationRefillsPage />} />
        <Route path={ROUTES.PROFILE.ROOT} element={<UserProfilePage />} />

        <Route path={ROUTES.MY_HEALTH.ROOT} element={<MyHealthPage />}>
          <Route index element={<Navigate replace to={ROUTES.MY_HEALTH.MY_HEALTH_DASHBOARD} />} />
          <Route path={ROUTES.MY_HEALTH.MY_HEALTH_DASHBOARD} element={<DashboardPage />} />
          <Route path={ROUTES.MY_HEALTH.GOALS} element={<MyGoalsPage />} />
          <Route path={ROUTES.MY_HEALTH.CARE_GUIDELINES.ROOT} element={<CareGuidelinesPage />}>
            <Route index element={<CareGuidelinesListPage />} />
            <Route path={ROUTES.MY_HEALTH.CARE_GUIDELINES.ITEM} element={<CareGuidelinesItemPage />} />
          </Route>
          <Route path={ROUTES.MY_HEALTH.HEALTH_SUMMARY.ROOT} element={<HealthSummaryPage />}>
            <Route path={ROUTES.MY_HEALTH.HEALTH_SUMMARY.MEDICATIONS} element={<MedicationsPage />} />
            <Route path={ROUTES.MY_HEALTH.HEALTH_SUMMARY.ALLERGIES} element={<AllergiesPage />} />
            <Route path={ROUTES.MY_HEALTH.HEALTH_SUMMARY.PATIENT_EDUCATION} element={<PatientEducationPage />} />
            <Route path={ROUTES.MY_HEALTH.HEALTH_SUMMARY.IMMUNIZATIONS} element={<ImmunizationsPage />} />
            <Route path={ROUTES.MY_HEALTH.HEALTH_SUMMARY.HEALTH_ISSUES} element={<HealthIssuesPage />} />
            <Route path={ROUTES.MY_HEALTH.HEALTH_SUMMARY.SCREENINGS} element={<ScreeningsPage />} />
            <Route path={ROUTES.MY_HEALTH.HEALTH_SUMMARY.VISIT_SUMMARIES} element={<VisitSummariesPage />} />
            <Route path={ROUTES.MY_HEALTH.HEALTH_SUMMARY.DOCUMENTS} element={<DocumentsPage />} />
          </Route>
          <Route path={ROUTES.MY_HEALTH.TEST_RESULTS} element={<TestResultsPage />} />
          <Route path={ROUTES.MY_HEALTH.RISK_PROFILE} element={<RiskProfilePage />} />
          <Route path={ROUTES.MY_HEALTH.QUESTIONNAIRES} element={<QuestionnairesListPage />} />
          <Route path={ROUTES.MY_HEALTH.QUESTIONNAIRES_START} element={<QuestionnairesPage />} />
        </Route>
        <Route path={ROUTES.MESSAGES.ROOT} element={<MessagesContainer />}>
          <Route index element={<MessagesPage />} />
          <Route path={ROUTES.MESSAGES.EDIT} element={<EditMessagePage />} />
        </Route>

        <Route path={ROUTES.APPOINTMENTS.ROOT} element={<AppointmentPageContainer />}>
          <Route index element={<Navigate replace to={ROUTES.APPOINTMENTS.SCHEDULE_APPOINTMENT} />} />
          {!isRestricted && (
            <>
              <Route path={ROUTES.APPOINTMENTS.SCHEDULE_APPOINTMENT} element={<ScheduleAppointmentsPage />} />
              <Route
                path={ROUTES.APPOINTMENTS.COMPLETE_LIST_OF_VISIT_REASONS}
                element={<CompleteListOfVisitReasonsPage />}
              />
              <Route path={ROUTES.APPOINTMENTS.CHOOSE_TIME} element={<AppointmentsChoseTimePage />} />
              <Route path={ROUTES.APPOINTMENTS.CONFIRM} element={<ScheduleConfirmationPage />} />
              <Route path={ROUTES.APPOINTMENTS.SCHEDULED} element={<AppointmentsScheduledPage />} />
              <Route path={ROUTES.APPOINTMENTS.CANCELLATION} element={<AppointmentCancellationConfirmPage />} />
            </>
          )}

          <Route path={ROUTES.APPOINTMENTS.PROVIDERS.ROOT} element={<ProvidersContainer />}>
            <Route index element={<ProvidersPage />} />
            <Route path={ROUTES.APPOINTMENTS.PROVIDERS.BIO} element={<ProviderBioPage />} />
          </Route>
          <Route path={ROUTES.APPOINTMENTS.HEALTH_CENTER_SERVICES} element={<HealthCenterServicesPage />} />
          <Route path={ROUTES.APPOINTMENTS.HEALTH_CENTER_SERVICES} element={<HealthCenterServicesPage />} />
          <Route path={ROUTES.APPOINTMENTS.HEALTH_CENTER_LOCATIONS} element={<HealthCenterLocationsPage />} />
          <Route path={ROUTES.APPOINTMENTS.HEALTH_CENTER_LOCATION_DETAILS} element={<HealthCenterDetailsPage />} />
          <Route path={ROUTES.APPOINTMENTS.PHYSICAL_THERAPY} element={<PhysicalTherapyPage />} />
        </Route>

        <Route path={ROUTES.LATEST_NEWS} element={<LatestNewsPage />} />
        <Route path={ROUTES.FORMS.ROOT} element={<FormsPage />} />

        <Route path={ROUTES.RESOURCES.ROOT}>
          <Route index element={<Navigate replace to={ROUTES.RESOURCES.ALL} />} />
          <Route path={ROUTES.RESOURCES.ALL} element={<ResourcesPage />} />
          <Route path={ROUTES.RESOURCES.WEBINARS} element={<WebinarLibraryPage />} />
          <Route path={ROUTES.RESOURCES.WELLNESS_WORKSHOPS} element={<WellnessWorkShopsPage />} />
          <Route path={ROUTES.RESOURCES.WELLNESS_WORKSHOPS_DETAILS} element={<WellnessWorkShopsPage />} />
          <Route path={ROUTES.RESOURCES.HEALTHWISE} element={<HealthwisePage />} />
          <Route path={ROUTES.RESOURCES.BLUEBOOK} element={<HealthcareBluebookPage />} />
          <Route path={ROUTES.RESOURCES.COMMUNITY} element={<CommunityPage />} />
        </Route>

        <Route path={ROUTES.HELP.ROOT}>
          <Route index element={<Navigate replace to={ROUTES.HELP.FAQ} />} />
          <Route path={ROUTES.HELP.FAQ} element={<FAQSPage />} />
          <Route path={ROUTES.HELP.SPANISH_HELP} element={<SpanishHelpPage />} />
        </Route>

        <Route path={ROUTES.WELLNESS.ROOT}>
          <Route index element={<Navigate replace to={ROUTES.WELLNESS.WELLNESS_LOGS} />} />
          <Route path={ROUTES.WELLNESS.WELLNESS_LOGS} element={<WellnessLogPage />} />
          <Route path={ROUTES.WELLNESS.CHALLENGES} element={<ChallengesPage />} />
          <Route path={ROUTES.WELLNESS.INCENTIVES} element={<IncentivesPage />} />
        </Route>

        <Route path={ROUTES.CATEGORIES.ROOT}>
          <Route path={ROUTES.CATEGORIES.CATEGORY} element={<CustomCategoryPage />} />
        </Route>

        <Route path={ROUTES.COVID_SCREENING.ROOT} element={<Covid19ScreeningPage />}>
          <Route index element={<Covid19QuestionnairePage />} />
          <Route path={ROUTES.COVID_SCREENING.RESULT} element={<Covid19ResultPage />} />
        </Route>
      </Route>
      <Route path={ROUTES.SIGN_IN} element={<Navigate replace to={ROUTES.LOGIN} />} />
      <Route path={ROUTES.LOGIN} element={<LoginPage />} />
      <Route path={ROUTES.PASSWORD_RECOVERY.ROOT} element={<PasswordRecoveryPage />} />
      <Route path={ROUTES.PASSWORD_RECOVERY.RESET_PASSWORD} element={<ResetPasswordPage />} />

      <Route path={ROUTES.REGISTRATION.VALIDATION} element={<ValidationPage />} />
      <Route path={ROUTES.REGISTRATION.ROOT} element={<RegistrationPage />} />
      <Route path={ROUTES.REGISTRATION.REGISTRATION_CAMPAIGN} element={<RegistrationPage />} />
      <Route path={ROUTES.REGISTRATION.SIGN_UP} element={<Navigate to={ROUTES.REGISTRATION.ROOT} replace />} />
      <Route
        path={ROUTES.REGISTRATION.SIGN_UP_CAMPAIGN}
        element={<ParametricNavigate to={ROUTES.REGISTRATION.REGISTRATION_CAMPAIGN} replace />}
      />

      <Route path={ROUTES.REGISTRATION.COMPLETE} element={<RegistrationPage />} />
      <Route path={ROUTES.REGISTRATION.COMPLETE_WITHOUT_TOKEN} element={<RegistrationPage />} />

      <Route path={ROUTES.SERVICE} element={<InternalServerErrorPage />} />
      <Route path={ROUTES.MAINTENANCE} element={<MaintenancePage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default App;
