/* eslint-disable no-param-reassign */
import { PayloadAction } from '@reduxjs/toolkit';

import { userProfileService } from '@common/UserProfileService';
import { StatusEnum } from '@common/constants';

import { SaveMetaType } from '../types';
import { FamilyMemberProfileType, UserProfileState } from './types';

export const saveUserDataPending = (state: UserProfileState) => {
  state.saveStatus = StatusEnum.Pending;
};

export const saveUserDataRejected = (state: UserProfileState) => {
  state.saveStatus = StatusEnum.Rejected;
};

export const saveUserDataFulfilled = (
  state: UserProfileState,
  action: PayloadAction<unknown, string, SaveMetaType>,
) => {
  const { id } = action.meta.arg;
  if (id !== state.userProfileData.id) {
    const familyMemberState = state.familyMembersProfiles[id] ?? ({} as FamilyMemberProfileType);
    state.familyMembersProfiles[id] = {
      ...familyMemberState,
      data: {
        ...familyMemberState.data,
        ...action.meta.arg,
      },
    };
  } else {
    state.userProfileData = {
      ...state.userProfileData,
      ...action.meta.arg,
      password: '',
      confirm_password: '',
    };
  }
  const userProfile = userProfileService.getUserProfile();
  const wellnessId = state?.userProfileData?.wellness_id ?? '';

  userProfileService.saveUserProfile(
    {
      ...userProfile,
      user: { ...userProfile.user, ...action.meta.arg },
    },
    wellnessId ?? '',
  );

  state.saveStatus = StatusEnum.Fulfilled;
};
