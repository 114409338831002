import React, { ReactNode, useCallback, useState } from 'react';

import classNames from 'classnames/bind';

import { DropDownMenuItem } from '@components/DropDownMenuItem';
import { MenuItemType, TabProps } from '@components/MenuInsideModule';
import { SvgIcon } from '@components/SvgIcon';
import { ShowCategory } from '@modules/categories';

import { locale } from './locale';

import styles from './MobileSubMenu.module.css';

const cn = classNames.bind(styles);

export interface MobileSubMenuProps {
  menuItems: Array<TabProps>;
  onSubMenuItemClick?: (menuItem: MenuItemType, to?: string) => void;
  onMenuItemClick?: (menuItem: TabProps, to?: string) => void;
  handleClose: () => void;
  selectedTab?: string | number | undefined;
  renderCustomMenuItem?: Nullable<(menuItem: TabProps) => ReactNode>;
}

export function MobileSubMenu({
  menuItems,
  onSubMenuItemClick,
  handleClose,
  onMenuItemClick,
  selectedTab,
  renderCustomMenuItem,
}: MobileSubMenuProps) {
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const [openMenuItems, setOpenMenuItems] = useState<Array<MenuItemType>>();

  const handleCloseAndChoose = useCallback(
    (menuItem: TabProps) => {
      onMenuItemClick?.(menuItem);
      handleClose();
    },
    [onMenuItemClick, handleClose],
  );

  const handleItemWithSubMenu = useCallback(
    (menuItem: TabProps) => {
      onMenuItemClick?.(menuItem);
      setOpenMenuItems(menuItem.subMenuItems);
      setSubMenuOpen(true);
    },
    [onMenuItemClick, handleClose],
  );

  const handleSubMenuCloseAndChoose = useCallback(
    (menuItem: MenuItemType) => {
      onSubMenuItemClick?.(menuItem);
      handleClose();
    },
    [onSubMenuItemClick, handleClose],
  );

  const handleSubMenuBack = () => {
    setSubMenuOpen(false);
    setOpenMenuItems([]);
  };

  const renderMenuItem = useCallback(
    (tab: TabProps, i: number) => {
      if (tab.subMenuItems) {
        return (
          <ShowCategory key={tab.id} category={tab.label || ''}>
            <DropDownMenuItem
              className={cn('mobile-submenu__item', {
                'mobile-submenu__item--active': selectedTab === tab.id,
              })}
              contentClassName={cn('mobile-submenu__item-content')}
              onClick={() => handleItemWithSubMenu(tab)}
              badge={tab.badge}
              autoFocus={i === 0}
            >
              <span
                className={cn('mobile-submenu__item-inner', {
                  'mobile-submenu__item-inner--active': selectedTab === tab.id,
                })}
              >
                {tab.label}
              </span>
              <SvgIcon icon="chevronRight" className={cn('mobile-submenu__item-chevron')} />
            </DropDownMenuItem>
          </ShowCategory>
        );
      }

      return (
        <ShowCategory key={tab.id} category={tab.label || ''}>
          <DropDownMenuItem
            className={cn('mobile-submenu__item', {
              'mobile-submenu__item--active': selectedTab === tab.id,
            })}
            contentClassName={cn('mobile-submenu__item-content')}
            onClick={() => handleCloseAndChoose(tab)}
            badge={tab.badge}
            autoFocus={i === 0}
            to={tab.to}
          >
            <span
              className={cn('mobile-submenu__item-inner', {
                'mobile-submenu__item-inner--active': selectedTab === tab.id,
              })}
            >
              {tab.label}
            </span>
          </DropDownMenuItem>
        </ShowCategory>
      );
    },
    [handleCloseAndChoose, selectedTab],
  );

  if (subMenuOpen) {
    return (
      <div>
        <ShowCategory key="back" category="back">
          <DropDownMenuItem contentClassName={cn('mobile-submenu__item-content')} onClick={() => handleSubMenuBack()}>
            <SvgIcon icon="chevronLeft" className={cn('mobile-submenu__back-chevron')} />
            <span>{locale.back}</span>
          </DropDownMenuItem>
        </ShowCategory>

        {openMenuItems?.map((tab) => (
          <ShowCategory key={tab.id} category={tab.label || ''}>
            <DropDownMenuItem
              className={cn('mobile-submenu__item', {
                'mobile-submenu__item--active': selectedTab === tab.id,
              })}
              contentClassName={cn('mobile-submenu__item-content')}
              onClick={() => handleSubMenuCloseAndChoose(tab)}
              badge={tab.badge}
              to={tab.to}
            >
              <span
                className={cn('mobile-submenu__item-inner', {
                  'mobile-submenu__item-inner--active': selectedTab === tab.id,
                })}
              >
                {tab.label}
              </span>
            </DropDownMenuItem>
          </ShowCategory>
        ))}
      </div>
    );
  }

  return (
    <div>
      {menuItems.map((tab, i) => (
        <div key={tab.label}>{renderCustomMenuItem ? renderCustomMenuItem(tab) : renderMenuItem(tab, i)}</div>
      ))}
    </div>
  );
}
