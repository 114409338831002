import React, { useCallback } from 'react';

import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';

import { tokenService } from '@common/TokenService';
import { StatusEnum } from '@common/constants';
import { useIgnoreEffectDeps } from '@common/hooks';
import { useQueryParamsObject } from '@common/router';
import { ROUTES } from '@common/routes';
import { Button } from '@components/Button';
import { SideBarWidget } from '@components/SideBarWidget';
import { Typography } from '@components/Typography';
import { toggleLocker } from '@modules/GlobalLocker/store/store';

import { useLoginRedirect } from '../../hooks';
import { locale } from '../../locale';
import { getImpersonationUserIdSelector, getLoginError, getLoginStatus, impersonate, reset } from '../../store';
import { Description } from '../Description';
import { Errors } from '../Errors';

import styles from './Impersonation.module.css';

const cn = classNames.bind(styles);

type Props = {
  className: string;
};

export const Impersonation = ({ className }: Props) => {
  const dispatch = useDispatch<ThunkAppDispatch>();
  const { userId } = useParams();
  const { salesForceImpersonation } = useQueryParamsObject();
  const error = useSelector(getLoginError);
  const status = useSelector(getLoginStatus);
  const impersonationUserId = useSelector(getImpersonationUserIdSelector);
  const navigate = useNavigate();
  const token = tokenService.getToken();

  const handleRetry = useCallback(() => {
    if (userId) {
      dispatch(impersonate({ userId, token, salesForceImpersonation }));
    } else {
      navigate(ROUTES.LOGIN);
    }
  }, [dispatch, navigate, salesForceImpersonation, token, userId]);

  const handleGoBackToAdminClick = useCallback(() => {
    dispatch(toggleLocker({ showLocker: true }));
    window.location.replace(
      process.env.ADMIN_URL.match('^http')
        ? `${process.env.ADMIN_URL}/users/${impersonationUserId}`
        : `https://${process.env.ADMIN_URL}/users/${impersonationUserId}`,
    );
  }, [dispatch, impersonationUserId]);

  useLoginRedirect();

  useIgnoreEffectDeps(() => {
    dispatch(reset())
      .unwrap()
      .then(() => {
        handleRetry();
      });
  }, []);

  return (
    <SideBarWidget className={cn('root', className)}>
      <Description />
      <div className={cn('wrapper')}>
        <div className={cn('container')}>
          <Errors />
          <Typography variant="hxl" className={cn('title')}>
            {locale.impersonation.enter}
          </Typography>
          <div className={cn('button-container')}>
            <Button variant="contained" disabled={!error && status === StatusEnum.Pending} onClick={handleRetry}>
              {locale.impersonation.retry}
            </Button>
            <Button
              variant="contained"
              disabled={!error && status === StatusEnum.Pending}
              onClick={handleGoBackToAdminClick}
            >
              {locale.impersonation.go_back_admin}
            </Button>
          </div>
        </div>
      </div>
    </SideBarWidget>
  );
};
