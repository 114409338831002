import React, { MouseEventHandler, useCallback, useRef } from 'react';

import Popper from '@mui/material/Popper';
import classNames from 'classnames/bind';

import { useFocusElement } from '@common/hooks/useFocusElement';
import { Button } from '@components/Button';
import { SvgIcon } from '@components/SvgIcon';

import { AmPmEnum, ChangeValueActionsEnum, INITIAL_MINUTES, MAX_MINUTES, INITIAL_HOURS, ITEM_IDS } from './constants';
import { locale } from './locale';
import { useKeyboardClick } from './useKeyboardClick';
import { getAmPmHours, addZeroBefore, getMaxHours, getNewHoursValue, getAmPmValue } from './utils';

import styles from './ClockPicker.module.css';

const cn = classNames.bind(styles);

type DateType = string | number | Date | null;

export type ClockPickerProps = {
  anchorEl: HTMLDivElement | null;
  open: boolean;
  ampm?: boolean;
  value: DateType;
  onChange: (date: DateType) => void;
  handleClose: () => void;
};

const MAX_TAB_INDEX = 1;
const AM_FOCUS_INDEX = 0;
const PM_FOCUS_INDEX = 1;

export function ClockPicker({ value = '', ampm = false, anchorEl, open, onChange, handleClose }: ClockPickerProps) {
  const rootRef = useRef<HTMLDivElement | null>(null);
  const dateValue = value ? new Date(value) : new Date();
  const initialHours = value ? dateValue?.getHours() : INITIAL_HOURS;
  const initialMinutes = value ? new Date(value).getMinutes() : INITIAL_MINUTES;
  const hours = ampm ? getAmPmHours(initialHours) : initialHours;
  const amPmValue = ampm ? getAmPmValue(initialHours) : null;
  const maxHours = getMaxHours(amPmValue);

  const { setFocusedIndex } = useFocusElement({
    shouldSubscribe: true,
    maxTabIndex: MAX_TAB_INDEX,
    rootElement: rootRef.current,
    subscribeRoot: true,
    focusElement: true,
    initialFocusIndex: null,
  });

  const handleAmClick = useCallback<MouseEventHandler>(
    (event) => {
      event.preventDefault();
      if (amPmValue !== AmPmEnum.AM) {
        dateValue.setHours(initialHours - 12);
        onChange(dateValue);
      }
    },
    [dateValue, initialHours, onChange, amPmValue],
  );

  const handlePmClick = useCallback<MouseEventHandler>(
    (event) => {
      event.preventDefault();
      if (amPmValue !== AmPmEnum.PM) {
        dateValue.setHours(initialHours + 12);
        onChange(dateValue);
      }
    },
    [dateValue, initialHours, onChange, amPmValue],
  );

  const changeHours = useCallback(
    (changeValueActions: ChangeValueActionsEnum) => {
      const newHoursValue = getNewHoursValue({ hours, maxHours, amPmValue, changeValueActions });
      dateValue.setHours(newHoursValue);
      onChange(dateValue);
    },
    [amPmValue, dateValue, hours, maxHours, onChange, amPmValue, value],
  );

  const handleHoursArrowUpClick = useCallback(() => {
    changeHours(ChangeValueActionsEnum.Add);
  }, [changeHours]);

  const handleHoursArrowDownClick = useCallback(() => {
    changeHours(ChangeValueActionsEnum.Subtract);
  }, [changeHours]);

  const changeMinutes = useCallback(
    (newMinutesValue: number) => {
      dateValue.setMinutes(newMinutesValue);
      onChange(dateValue);
    },
    [dateValue, onChange],
  );

  const handleMinutesArrowUpClick = useCallback(() => {
    const newMinutesValue = initialMinutes + 1;
    changeMinutes(newMinutesValue > MAX_MINUTES ? INITIAL_MINUTES : newMinutesValue);
  }, [changeMinutes, initialMinutes]);

  const handleMinutesArrowDownClick = useCallback(() => {
    const newMinutesValue = initialMinutes - 1;
    changeMinutes(newMinutesValue < INITIAL_MINUTES ? MAX_MINUTES : newMinutesValue);
  }, [changeMinutes, initialMinutes]);

  useKeyboardClick({
    handleHoursArrowDownClick,
    handleHoursArrowUpClick,
    handleMinutesArrowDownClick,
    handleMinutesArrowUpClick,
    handleClose,
  });

  const handleAmFocus = () => {
    setFocusedIndex(AM_FOCUS_INDEX);
  };

  const handlePmFocus = () => {
    setFocusedIndex(PM_FOCUS_INDEX);
  };

  return (
    <Popper className={cn('root')} anchorEl={anchorEl} open={open} disablePortal>
      <div
        className={cn('time', 'hours')}
        tabIndex={0}
        role="spinbutton"
        title={locale.hours}
        aria-label={locale.hours}
        id={ITEM_IDS.HOURS}
        aria-valuenow={hours}
      >
        {addZeroBefore(getAmPmHours(hours, ampm))}
        <div className={cn('arrows')}>
          <SvgIcon icon="arrowDropUp" inheritViewBox className={cn('icon')} onClick={handleHoursArrowUpClick} />
          <SvgIcon icon="arrowDropDown" inheritViewBox className={cn('icon')} onClick={handleHoursArrowDownClick} />
        </div>
      </div>
      <div className={cn('dots')}>:</div>
      <div
        className={cn('time', 'minutes')}
        tabIndex={0}
        role="spinbutton"
        title={locale.minutes}
        aria-label={locale.minutes}
        id={ITEM_IDS.MINUTES}
        aria-valuenow={initialMinutes}
      >
        {addZeroBefore(initialMinutes)}
        <div className={cn('arrows')}>
          <SvgIcon icon="arrowDropUp" inheritViewBox className={cn('icon')} onClick={handleMinutesArrowUpClick} />
          <SvgIcon icon="arrowDropDown" inheritViewBox className={cn('icon')} onClick={handleMinutesArrowDownClick} />
        </div>
      </div>
      {ampm && (
        <div className={cn('am-pm-selection')} ref={rootRef}>
          <Button
            className={cn('am-pm-label', {
              'am-pm-label--selected': amPmValue === AmPmEnum.AM,
            })}
            disableRipple
            disableFocusVisible
            noHover
            onClick={handleAmClick}
            tabIndex={0}
            onFocus={handleAmFocus}
            data-tabindex={AM_FOCUS_INDEX}
          >
            {locale.am}
          </Button>
          <Button
            className={cn('am-pm-label', {
              'am-pm-label--selected': amPmValue === AmPmEnum.PM,
            })}
            disableRipple
            disableFocusVisible
            noHover
            onClick={handlePmClick}
            tabIndex={0}
            onFocus={handlePmFocus}
            data-tabindex={PM_FOCUS_INDEX}
          >
            {locale.pm}
          </Button>
        </div>
      )}
    </Popper>
  );
}
