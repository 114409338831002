import axios, { AxiosInstance } from 'axios';

import { ERROR_MESSAGES, METHODS } from './constants';
import {
  Config,
  IFetch,
  RejectedCallback,
  ExtendedAxiosRequestConfig,
  RequestInterceptor,
  RequestSuccessCallback,
  ResponseInterceptor,
  ResponseSuccessCallback,
} from './types';
import { paramsSerializer } from './utils';

export class Fetch implements IFetch {
  private axios: AxiosInstance;

  refreshTokenInProcess: Nullable<Promise<void>>;

  private abortMap: Array<any>;

  constructor({ config, requestInterceptors = [], responseInterceptors = [] }: Config = {}) {
    this.axios = axios.create(config);

    this.axios.defaults.paramsSerializer = paramsSerializer;
    requestInterceptors?.forEach((interceptor) => {
      this.registerRequestInterceptor(interceptor);
    });

    responseInterceptors?.forEach((interceptor) => {
      this.registerResponseInterceptor(interceptor);
    });

    this.refreshTokenInProcess = null;
    this.abortMap = [];
  }

  registerRequestInterceptor({ onSuccess, onError }: RequestInterceptor) {
    this.axios.interceptors.request.use(onSuccess as RequestSuccessCallback, onError as RejectedCallback);
  }

  registerResponseInterceptor({ onSuccess, onError }: ResponseInterceptor) {
    this.axios.interceptors.response.use(onSuccess as ResponseSuccessCallback, onError as RejectedCallback);
  }

  removeFromMap(controller: AbortController) {
    const controllerIndex = this.abortMap.findIndex((c) => c === controller);
    this.abortMap.splice(controllerIndex, 1);
  }

  abortAll() {
    this.abortMap.forEach((controller) => {
      controller.abort(ERROR_MESSAGES.CANCELED);
    });
    this.abortMap = [];
  }

  request({ onSuccess, onError, ...request }: ExtendedAxiosRequestConfig) {
    const controller = new AbortController();
    this.abortMap.push(controller);

    const responsePromise = this.axios
      .request({ ...request, signal: request.signal || controller.signal } as ExtendedAxiosRequestConfig)
      .then((data) => {
        // a simple way to process a request through callbacks + the ability to process Promise and at the call site remains
        this.removeFromMap(controller);
        return Promise.resolve(onSuccess ? onSuccess(data) : data);
      })
      .catch((error) => {
        const isCancelled = error?.message === ERROR_MESSAGES.CANCELED;
        const rejectError = isCancelled ? error?.message : error;

        return Promise.reject(onError ? onError(rejectError) : rejectError);
      });

    return {
      response: responsePromise,
      abort: () => {
        controller.abort();
      },
    };
  }

  get(request: Omit<ExtendedAxiosRequestConfig, 'method'>) {
    return this.request({ ...request, method: METHODS.GET });
  }

  post(request: Omit<ExtendedAxiosRequestConfig, 'method'>) {
    return this.request({ ...request, method: METHODS.POST });
  }

  put(request: Omit<ExtendedAxiosRequestConfig, 'method'>) {
    return this.request({ ...request, method: METHODS.PUT });
  }

  delete(request: Omit<ExtendedAxiosRequestConfig, 'method'>) {
    return this.request({ ...request, method: METHODS.DELETE });
  }

  options(request: Omit<ExtendedAxiosRequestConfig, 'method'>) {
    return this.request({ ...request, method: METHODS.OPTIONS });
  }

  patch(request: Omit<ExtendedAxiosRequestConfig, 'method'>) {
    return this.request({ ...request, method: METHODS.PATCH });
  }
}
