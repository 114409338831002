import { useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, generatePath } from 'react-router-dom';

import { tokenService } from '@common/TokenService';
import { StatusEnum } from '@common/constants';
import { useIgnoreEffectDeps } from '@common/hooks';
import { PATHS } from '@common/routes';
import { SamlNavigationType } from '@common/types';

import { LoginErrorCodes } from '../constants';
import {
  getLoginStatus,
  samlNavigationTypeSelector,
  samlReturnUrlSelector,
  samlRegistrationNoticeTokenSelector,
  setError,
} from '../store';

export const useSamlLoginRedirect = () => {
  const loginStatus = useSelector(getLoginStatus);
  const samlNavigationType = useSelector(samlNavigationTypeSelector);
  const samlReturnUrl = useSelector(samlReturnUrlSelector);
  const samlRegistrationNoticeToken = useSelector(samlRegistrationNoticeTokenSelector);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const redirectUrl = useMemo(() => {
    if (loginStatus === StatusEnum.Fulfilled) {
      if (samlNavigationType === SamlNavigationType.AutoLogin && tokenService.getToken()) {
        return samlReturnUrl ?? '/';
      }

      if (samlNavigationType === SamlNavigationType.RegisterUser) {
        return generatePath(PATHS.REGISTER.COMPLETE, { token: samlRegistrationNoticeToken ?? undefined });
      }

      if (samlNavigationType === SamlNavigationType.NoMatchFound) {
        dispatch(setError(LoginErrorCodes.NoMatchFound));
      }
    }

    return PATHS.LOGIN;
  }, [loginStatus, samlNavigationType, samlReturnUrl, samlRegistrationNoticeToken]);

  useIgnoreEffectDeps(() => {
    if ([StatusEnum.Fulfilled, StatusEnum.Rejected].includes(loginStatus)) {
      if (loginStatus === StatusEnum.Rejected || samlNavigationType !== SamlNavigationType.AutoLogin) {
        tokenService.removeToken();
      }

      if (redirectUrl.match('^http')) {
        window.location.replace(redirectUrl);
      } else {
        navigate(redirectUrl, { replace: true });
      }
    }
  }, [loginStatus]);
};
