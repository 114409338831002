import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { StatusEnum } from '@common/constants';
import { useIgnoreEffectDeps } from '@common/hooks';
import { useGlobalLocker } from '@modules/GlobalLocker';

import {
  getImpersonationRedirectSelector,
  getImpersonationUserIdSelector,
  getSalesForceImpersonationSelector,
  getLogoutStatus,
  logoutWithoutRevoke,
} from '../store';

export const useLogout = () => {
  const status = useSelector(getLogoutStatus);
  const impersonationRedirect = useSelector(getImpersonationRedirectSelector);
  const impersonationUserId = useSelector(getImpersonationUserIdSelector);
  const salesforceImpersonation = useSelector(getSalesForceImpersonationSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (status === StatusEnum.Rejected) {
      dispatch(logoutWithoutRevoke());
    }
  }, [status, dispatch]);

  useIgnoreEffectDeps(() => {
    if (impersonationRedirect && impersonationUserId && !salesforceImpersonation) {
      window.location.replace(
        process.env.ADMIN_URL.match('^http')
          ? `${process.env.ADMIN_URL}/users/${impersonationUserId}`
          : `https://${process.env.ADMIN_URL}/users/${impersonationUserId}`,
      );
    }
  }, [impersonationRedirect]);

  useGlobalLocker(status === StatusEnum.Pending || impersonationRedirect, { ariaLabel: 'Signing Out' });
};
